export const ContactUsApi = async contactData => {
  return await new Promise((resolve,reject) => {
    const formData = {
        formType: "contact", // Change based on the form
        email: contactData.email,
        name: contactData.name,
        comments: contactData.comments,
        subject: contactData.subject,
        gDRPolicy: contactData.GDRPolicy
      }
      const jsonString = encodeURIComponent(JSON.stringify(formData));
      const url = `https://script.google.com/macros/s/AKfycbykAh776-UhYdK8qJyb0NOISzGyMweTCxnj2rX8So9s-73BZp3cYCeUz_7teAbklOhp/exec?data=${jsonString}`;
  
      // Send GET request
      fetch(url).then(response => response.json())
      .then(data => {
        resolve(data);
    })
      .catch(error => {
        reject(error);
    });
  })
}
