import { Container, Row, Col } from 'react-bootstrap'
import {React} from 'react';
// import launchIdeas from '../../Images/launch-ideas.svg'
const LaunchIdeas = () => {
    return(
        <section className="launch-ideas-block">
            <Container>
                <div className="launchIdea-title-block">
                    <h4 className="launchIdea-title"><span>How it works!</span><br/>Five Steps To Launch an Idea</h4>
                </div>
                <Row style={{marginTop:"60px"}}>
                    <Col md="12" lg="12">
                        <div className="launch-step">
                            <div className="launch-step-content">
                                <h6 className="launch-step-title">Create Profile</h6>
                                <p>Register with your profile details and securely login to the platform.</p>
                            </div>
                        </div>
                        <div className="launch-step">
                            <div className="launch-step-content">
                                <h6 className="launch-step-title">Pitch An Idea</h6>
                                <p>Tell us about your business idea and we will turn it into a smart & strategic business plan.

                                </p>
                            </div>
                        </div>
                        <div className="launch-step">
                            <div className="launch-step-content">
                                <h6 className="launch-step-title">Complete Assessment</h6>
                                <p>We audit, assess and certify not only entrepreneurs personal
                                information but also all the business plan details before
                                publishing it to the platform.
                                </p>
                            </div>
                        </div>
                        <div className="launch-step">
                            <div className="launch-step-content">
                                <h6 className="launch-step-title">Collaborate with Investors & Professional Services</h6>
                                <p>We support multiple communication channels like emails,
                                sms, chat, calls etc to help you collaborate with potential
                                Investors & Professional Services.
                                </p>
                            </div>
                        </div>
                        <div className="launch-step">
                            <div className="launch-step-content">
                                <h6 className="launch-step-title">Connect with customers</h6>
                                <p>Connect with customers on the platform directly
                                </p>
                            </div>
                        </div>
                        <div className="launch-step launch-step-border-none">
                            <div className="launch-step-content">
                                <h6 className="launch-step-title">Grow your business</h6>
                            </div>
                        </div>
                    </Col>
                    {/* <Col md="6" lg="6" sm="6" className="col-span">
                        <div className="launch-image">
                            <img src={launchIdeas} alt="" />
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </section>
    )
}
export default LaunchIdeas;