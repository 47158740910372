export const ScheduleApi = async scheduleData => {
  return await new Promise((resolve,reject) => {
    const formData = {
        formType: "scheduleDemo", // Change based on the form
        email: scheduleData.email,
        name: scheduleData.name,
        phone: scheduleData.mobileNumber,
        proposedTime: scheduleData.proposedTime,
        proposedDate: scheduleData.proposedDate,
        fundingType:scheduleData.iam
      }
      const jsonString = encodeURIComponent(JSON.stringify(formData));
      const url = `https://script.google.com/macros/s/AKfycbykAh776-UhYdK8qJyb0NOISzGyMweTCxnj2rX8So9s-73BZp3cYCeUz_7teAbklOhp/exec?data=${jsonString}`;
  
      // Send GET request
      fetch(url).then(response => response.json())
      .then(data => {
        resolve(data);
    })
      .catch(error => {
        reject(error);
    });
 })
}
