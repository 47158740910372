export const RequestApi = async scheduleIcon => {
  return await new Promise((resolve,reject) => {
    const formData = {
      formType: "request", // Change based on the form
      email: scheduleIcon.email,
      name: scheduleIcon.name,
      phone: scheduleIcon.mobileNumber,
      visitPurpose: scheduleIcon.visitPurpose,
      consentToProcess: scheduleIcon.GDPCompalince
    }
    const jsonString = encodeURIComponent(JSON.stringify(formData));
    const url = `https://script.google.com/macros/s/AKfycbykAh776-UhYdK8qJyb0NOISzGyMweTCxnj2rX8So9s-73BZp3cYCeUz_7teAbklOhp/exec?data=${jsonString}`;

    // Send GET request
    fetch(url).then(response => response.json())
    .then(data => {
      resolve(data);
  })
    .catch(error => {
      reject(error);
  });
 })
}
