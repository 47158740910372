import { Container, Row, Col, InputGroup, FormControl, Button, Form } from 'react-bootstrap'
import {React, useEffect, useState} from 'react';
import { NewsLetterApi } from '../HubspotApis/Newsletterapi'
import NewsPaper from '../../Images/newspaper.png';
const NewsLetter = () =>{
    const [newsletter,setNewsLetter] = useState({email:'',GDPCompalince:false})
    useEffect(() => {
        if(newsletter.GDPCompalince){
            setnewsLetterDisableSubmt(false)
        }
        else {
            setnewsLetterDisableSubmt(true)
        }
    },[newsletter.GDPCompalince])
    const [newsLetterDisableSubmt,setnewsLetterDisableSubmt]=useState(true)
    const handleNewsletter = e =>{
        e.preventDefault()
        if(newsletter.email===''){
            alert('please Enter Email id')
        }
        else {
            NewsLetterApi(newsletter.email).then((res)=>{
                alert('you can recevie the daily updates through your email from ipowerz');
                setNewsLetter({...newsletter,email:''})
            }).catch(error => {
                console.log(error)
            })
        }
    }
    return(
        <section className="newsletter">
            <Container>
                <Row className="justify-center">
                    <Col md="10" lg="10" sm="10">
                        <Row>
                            <Col md="3" lg="3" sm="3">
                                <div className="newsletter-image">
                                    <img src={NewsPaper} alt="" />
                                </div>
                            </Col>
                            <Col md="9" lg="9" sm="9" className="self-end col-span">
                                <div className="newsletter-content">
                                    <h4 className="newsletter-title text-center">SUBSCRIBE TO OUR NEWSLETTER</h4>
                                    <div className="search-plans" style={{marginTop:"30px"}}>
                                        <Form onSubmit={handleNewsletter} noValidate>
                                            <InputGroup className="mb-3">
                                                <FormControl
                                                placeholder="email"
                                                aria-label="email"
                                                aria-describedby="basic-addon2"
                                                onChange={e=>setNewsLetter({...newsletter,email:e.target.value})} 
                                                value={newsletter.email}
                                                />
                                                <Button type="submit" variant="outline-secondary" id="button-addon2" disabled={newsLetterDisableSubmt}>
                                                Subscribe
                                                </Button>
                                            </InputGroup>
                                            <Form.Group className="mb-3 mt-3" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="By entering your email address, you agree to receive our marketing offers in accordance with our Privacy Policy" onChange={e=>setNewsLetter({...newsletter,GDPCompalince:e.target.checked})} value={newsletter.GDPCompalince} required style={{color:"#fff"}}/>
                                            </Form.Group>
                                            
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default NewsLetter;