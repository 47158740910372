import { Container, Row, Col, Accordion } from 'react-bootstrap'
const TermsConditionContent = () => {
    return (
        <section className="privacyPolicy">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12">
                    <p>Though all efforts have been made to ensure the accuracy and currency of the content on this website, the same should not be construed as a statement of law or used for any legal purposes.</p>
                        <Accordion className="mt-5 mb-5">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Creating a User Profile</Accordion.Header>
                                <Accordion.Body>
                                <p>We may allow you to create a user profile on our website so that each time you visit us we can provide you with a more personalised experience. We may also allow you to subscribe to a particular page of our Site and we may offer you the opportunity to be notified of any changes to that page. We may hold the information you provide us (e.g. when you create a user profile or agree to subscribe to our website) on our databases. By creating a user profile on our website or subscribing to a page of our Site you are agreeing that we may use your personal information in this way. You will have the opportunity to view, update or remove any personal information that you have provided to us by amending your user profile online.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Security</Accordion.Header>
                                <Accordion.Body>
                                    <p>No data transmission over the Internet can be guaranteed to be 100% secure. Therefore iPowerz.com cannot guarantee that personal or sensitive information remains confidential during transmission over the Internet. All e-mails sent to iPowerz.com will be monitored and checked to ensure our systems operate effectively.</p>
                                    <p>The personal information we collect from you online is stored by us on databases protected through access controls, firewall technology and other appropriate security measures. However, such security measures cannot prevent all loss, misuse or alteration of personal information and we are not responsible for any damage or liability relating to such incidents.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Links to and from iPowerz.com Site</Accordion.Header>
                                <Accordion.Body>
                                   <p>iPowerz.com cannot take any responsibility and makes no warranties, representations or undertakings about the content of any other website accessed by hypertext link. iPowerz.com has no control over the availability of the linked pages.</p>
                                  <p>If you click on a link found on our website or on any other website you can check the location bar within your browser to find out whether you have been linked to a different website.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Disclaimer</Accordion.Header>
                                <Accordion.Body>
                                    <p>Although every reasonable effort has been made to ensure that the information on this website is accurate at the time of publication, visitors who use this website and rely on any information do so at their own risk. iPowerz.com does not warranty its accuracy and disclaims any liability to any third party anywhere in the world for any injury, damage, loss or inconvenience arising as a consequence of any use of or the inability to use any information on this website to the fullest extent permitted at law.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Copyright</Accordion.Header>
                                <Accordion.Body>
                                    <p>The name iPowerz.com and all logos, slogans, programme names and designs are the trade marks, service marks, trade names and design rights of iPowerz.com and cannot be reproduced without the prior written consent of iPowerz.com. Where the names, logos and trademarks of third parties are displayed these are used with the permission of the owners. Copyright in the material contained on this website is owned by iPowerz.com or its content suppliers or licensors, as applicable.</p>
                                    <p>Nothing contained herein shall be construed as conferring any license by iPowerz.com to use any material displayed. Permission to reproduce any material on this website must be obtained from the copyright holder concerned.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Service Charges</Accordion.Header>
                                <Accordion.Body>
                                    <p>iPowerz may determine fees for its services and may make changes to the fees from time to time.</p>
                                    <p>Any questions regarding this website click here to <a href="#/contact">Contact us</a></p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default TermsConditionContent;