import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { React } from "react";
import FundIcon from "../../Images/icon-I need fund.svg";
import NeedFund from "../../Images/icon-I want to fund.svg";
/* import { NeedFundForm } from '../Forms/NeedFundForm';
import { FundProjectForm } from '../Forms/FundProjectForm';
*/
const WantFunds = () => {
  /**
   * Fund Need
   */
  // eslint-disable-next-line

  return (
    <section className="wantFunds ipowerz">
      <Container>
        <div className="idea-powerz-content">
          <div className="idea-title-left">
            <h4 className="idea-powerz-title">
              <span>Global</span>
              <br />
              Digital Ecosystem
            </h4>
          </div>
          <p>
            Join to raise funds or invest in validated business opportunities
            across the globe
          </p>
        </div>
        <Row style={{ marginTop: "30px" }} className="mentor-funds">
          <Col md="12" lg="12">
            <Tab.Container id="left-tabs-example" defaultActiveKey="needFund">
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" className="flex-column">
                    <Row>
                      <Col md="6" lg="6" sm="6" className="col-6">
                        <Nav.Item>
                          <Nav.Link eventKey="needFund">
                            <div className="image-icon">
                              <img
                                src={FundIcon}
                                alt="I need funds"
                                title="I need funds"
                              />
                              <div>I need funds</div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Col>
                      <Col md="6" lg="6" sm="6" className="col-6">
                        <Nav.Item className="mobile-space-tab">
                          <Nav.Link eventKey="fundProject">
                            <div className="image-icon">
                              <img
                                src={NeedFund}
                                alt="I want to fund"
                                title="I want to fund"
                              />
                              <div>I want to fund</div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Col>
                    </Row>
                  </Nav>
                </Col>
                <Col sm={8}>
                  <Tab.Content>
                    <Tab.Pane eventKey="needFund">
                      <div className="content-tab">
                        {/* <NeedFundForm /> */}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fundProject">
                      <div className="content-tab">
                        {/* <FundProjectForm /> */}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default WantFunds;
