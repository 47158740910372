import { Col, Container, Row } from 'react-bootstrap'
const TeamSection = () => {
    return(
        <section className="team-about-block">
            <Container>
                <Row>
                    <Col className="mt-5">
                       <p>To know more about us, visit the <a href={`${process.env.PUBLIC_URL}#/team`}>Team.</a></p>
                       <p>For joining the iPowerz entrepreneurship ecosystem, click here to  <a href="#/contact">Contact us</a></p>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}
export default TeamSection;